import React, { Component } from 'react';
import { Route, Link } from 'react-router-dom';
import { Nav, Collapse, Container, Row, Col, Navbar, NavbarBrand, NavLink, NavItem } from 'reactstrap';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import Login from '../../main/containers/login';
import f4flogo from '../../assets/images/F4F.png';
import { checkIfLoginIsPossible } from '../../main/utils/authUtils';
import { __env } from '../../envloader';
import FeedbackModal from './feedbackModal';

@withTranslation()
@connect(
  state => ({
    loginStore: state.login.get('loginData'),
  })
)
export default class TopBar extends Component {

  render() {
    const { t } = this.props;
    return (
      <header>
        <Container fluid>
          <Row>
            <Col>
              <Navbar color="faded" expand>
                <Collapse navbar>
                  <Nav className="ml-auto" navbar pills >
                    <NavItem className="mr-2">
                      <NavLink href="https://www.fair4fusion.eu/">
                        <img src={f4flogo} alt={t('fair4fusion')} className={"mr-2"} />
                        {t('fair4fusion')}
                      </NavLink>
                    </NavItem>
                    <Route strict path='/' render={props => (
                      <Login location={props.location} />
                    )} />
                  </Nav>
                </Collapse>
              </Navbar>
            </Col>
          </Row>
          { ( this.props.loginStore || !checkIfLoginIsPossible() )  && <Row
            className={ "banner align-items-center" + (__env.DASHBOARD_FLAVOUR_NUMBER===1 ? " banner-west":"") }>
            <Col className="ml-md-4">
              <NavbarBrand tag={Link} to="/">{t('name')}</NavbarBrand>
            </Col>
            <Col className={"text-center"}>
              <FeedbackModal/>
            </Col>
            <Col className="mr-md-4"/>
          </Row> }
        </Container>
      </header>
    );
  }
}

TopBar.propTypes = {
  loginStore: PropTypes.object, //HOC
  t: PropTypes.func //HOC
};
