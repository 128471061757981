import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { useDispatch } from 'react-redux';
import { Button, Input, InputGroup, InputGroupAddon, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import PropTypes from 'prop-types';

import { withSendRequest } from '../../main/hoc/withSendRequest';
import { ErrorBoundary } from '../../main/hoc/errorboundary';
import useForm from '../hooks/useForm';
import { getExperimentInfo } from '../actions/experimentOverview';
import ExtraUriList from '../components/extraUriList';
import * as notify from '../../main/utils/notify';

const ExtraUriModal = ({ isOpen, toggle, experimentId, sendRequest }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [ attribute, setAttribute ] = useState(null);
  const [ isLoading, setIsLoading ] = useState(false);
  const [ editedUri, setEditedUri ] = useState('');
  const { fields, handleFieldChange, setFields } = useForm({
    extraUri: ''
  });

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const response = await sendRequest('get', `/attribute?experimentId=${experimentId}&name=extraUri`);
        setAttribute(response.data[0]);
        setIsLoading(false);
      }
      catch (error) {
        notify.error(
          t('extra_uri_modal.notifications.read_error.title'),
          t('extra_uri_modal.notifications.read_error.body')
        );
        setIsLoading(false);
      }
    })();
  }, [ experimentId, sendRequest, t ]);

  const handleAddOrEdit = () => {
    if (editedUri) {
      if (editedUri === fields.extraUri) {
        setFields({ extraUri: '' });
        setEditedUri('');
        return;
      }
      if (attribute.value.includes(fields.extraUri)) {
        notify.error(
          t('extra_uri_modal.notifications.duplicate_error.title'),
          t('extra_uri_modal.notifications.duplicate_error.body')
        );
        return;
      }
      setAttribute(prev => ({
        ...prev,
        value: prev.value.map(u => u === editedUri ? fields.extraUri : u),
      }));
      setFields({ extraUri: '' });
      setEditedUri('');
      return;
    }

    if (attribute.value.includes(fields.extraUri)) {
      notify.error(
        t('extra_uri_modal.notifications.duplicate_error.title'),
        t('extra_uri_modal.notifications.duplicate_error.body')
      );
      return;
    }
    setAttribute(prev => ({ ...prev, value: [ ...prev.value, fields.extraUri ] }));
    setFields({ extraUri: '' });
  };

  const handleDelete = (uri) => {
    setEditedUri('');
    setFields({ extraUri: '' });
    setAttribute(prev => ({ ...prev, value: prev.value.filter(u => u !== uri) }));
  };

  const handleEdit = (uri) => {
    setEditedUri(uri);
    setFields({ extraUri: uri });
  };

  const handleSave = async () => {
    try {
      setIsLoading(true);
      const response = await sendRequest('put', `/attribute/${attribute.id}`, attribute);
      setAttribute(response.data);
      await dispatch(getExperimentInfo({ sendRequest, experimentId }));
      setIsLoading(false);
    }
    catch (error) {
      notify.error(
        t('extra_uri_modal.notifications.create_error.title'),
        t('extra_uri_modal.notifications.create_error.body')
      );
      setIsLoading(false);
    }

    toggle();
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} size='lg'>
      <ModalHeader toggle={toggle}>{t('extra_uri_modal.header_title')}</ModalHeader>
      <ModalBody>
        <ExtraUriList
          uriList={attribute?.value ?? []}
          onDelete={handleDelete}
          onEdit={handleEdit}
          isLoading={isLoading} />
        <Label htmlFor='extraUri' className='mt-3'>
          {editedUri ? t('extra_uri_modal.input_label_edit') : t('extra_uri_modal.input_label')}
        </Label>
        <InputGroup>
          <Input
            id='extraUri'
            name='extraUri'
            onChange={handleFieldChange}
            value={fields.extraUri}
            placeholder={t('extra_uri_modal.input_placeholder')}
          />
          <InputGroupAddon addonType='append'>
            <Button
              type='button'
              color='primary'
              onClick={handleAddOrEdit}
              disabled={fields.extraUri.length===0 || fields.extraUri.length>1024}
            >
              {editedUri ? t('extra_uri_modal.edit_button_label') : t('extra_uri_modal.add_button_label') }
            </Button>
          </InputGroupAddon>
        </InputGroup>
      </ModalBody>
      <ModalFooter>
        <Button type='button' color='primary' onClick={handleSave} disabled={isLoading}>
          {t('extra_uri_modal.save_button_label')}
        </Button>
        <Button type='button' onClick={toggle}>
          {t('extra_uri_modal.cancel_button_label')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

ExtraUriModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  experimentId: PropTypes.string.isRequired,
  sendRequest: PropTypes.func, // HOC
};

export default compose(
  withSendRequest,
  ErrorBoundary((props) => props.t('components.extra_uri_modal'))
)(ExtraUriModal);
