import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, Container, CustomInput } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import ExperimentItem from './experimentItem';
import ExperimentsPagination from './experimentsPagination';
import Loading from '../../main/components/loading';
import { withSendRequest } from '../../main/hoc/withSendRequest';
import { toggleSwitch, updateFilters, getExperiments, setSearch } from '../actions/experimentsList';
import { getExperimentsRequestParams } from '../utils';
import { ErrorBoundary } from '../../main/hoc/errorboundary';
import AddExperimentModal from './addExperimentModal';

const ExperimentsContainer = ({ sendRequest, variables }) => {
  const [ switchOpen, setSwitchOpen ] = useState(false);
  const [ addExperimentModalOpen, setAddExperimentModal ] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    const search = decodeURIComponent(location.search);

    if (search) {
      const { conditions, machines, variables, page } = getExperimentsRequestParams(search);
      dispatch(updateFilters(conditions));
      dispatch(getExperiments({
        sendRequest,
        conditions,
        variables,
        machines,
        page
      }));
      dispatch(setSearch(search));
      dispatch(toggleSwitch(true));
    } 
    else {
      dispatch(updateFilters([]));
      dispatch(getExperiments({ sendRequest }));
    }
  }, [ sendRequest, dispatch, location ]);

  const switchOpenGlobal = useSelector(state => state.experiments.get('switchOpen'));

  useEffect(() => {
    setSwitchOpen(switchOpenGlobal);
  }, [ switchOpenGlobal ]);

  const handleToggle = (e) => {
    dispatch(toggleSwitch(e.target.checked));
  };

  const toggleAddExperimentModal = () => setAddExperimentModal(prev => !prev);

  const experimentsData = useSelector((state) => state.experiments.get('experimentsData'));
  const loading = experimentsData.get('loading');
  const error = experimentsData.get('error');
  const experiments = experimentsData.get('experiments');

  const addExperimentModalAndButton = () => {
    return (
      <>
        { addExperimentModalOpen && <AddExperimentModal
          isOpen={addExperimentModalOpen}
          toggle={toggleAddExperimentModal}
        /> }
        <Container className='d-flex justify-content-end'>
          <Button
            color='primary'
            onClick={toggleAddExperimentModal}
          >
            {t('add_experiment_button')}
          </Button>
        </Container>
      </>
    );
  };

  if (error !== null) {
    return <p className='text-center'>{error}</p>;
  }

  if (loading || !experiments) {
    return <Loading className='text-center' />;
  }

  if (experiments.length === 0) {
    return (
      <>
        {addExperimentModalAndButton()}
        <p className='text-center no-results'>{t('no_results_message')}</p>
      </>
    );
  }

  return (
    <>
      {addExperimentModalAndButton()}
      <Container>
        <div className='my-3 d-flex align-items-center justify-content-between'>
          <CustomInput
            type='switch'
            id='open-plots'
            name='open-plots'
            label={t('toggle_plots_switch_text')}
            checked={switchOpen}
            onChange={(e) => handleToggle(e)}
          />
        </div>
        <section className='accordion'>
          {experiments.map((exp, index) => (
            <ExperimentItem
              key={exp.experiment.experiment_id}
              experiment={exp.experiment}
              entryData={exp.entry_data}
              index={index}
              variables={variables}
            />
          ))}
        </section>
        <ExperimentsPagination />
      </Container>
    </>
  );
};

ExperimentsContainer.propTypes = {
  sendRequest: PropTypes.func.isRequired, //HOC
  variables: PropTypes.object,
};

export default compose(
  withSendRequest,
  ErrorBoundary((props) => props.t('components.experiments_container'))
)(ExperimentsContainer);
