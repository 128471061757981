import PropTypes from 'prop-types';
import React from 'react';
import { ListGroup, ListGroupItem } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import Loading from '../../main/components/loading';

const ExtraUriList = ({ uriList, onDelete, onEdit, isLoading }) => {
  const { t } = useTranslation();

  if (!uriList || isLoading) {
    return <Loading className='text-center' />;
  }

  if (uriList.length === 0) {
    return <p className='text-center'>{t('extra_uri_modal.extra_uri_not_found')}</p>;
  }

  return (
    <ListGroup>
      {uriList.map(uri =>
        <ListGroupItem key={uri}>
          <div className='d-flex justify-content-between align-items-center'>
            <span>{uri}</span>
            <span className='d-flex justify-content-between align-items-center'>
              <button
                className='btn-bg-image btn-annotate mr-2'
                title={t('extra_uri_modal.edit_button_title')}
                onClick={() => onEdit(uri)}
              >
              </button>
              <button
                className='btn-bg-image btn-remove mr-2'
                title={t('extra_uri_modal.delete_button_title')}
                onClick={() => onDelete(uri)}>
              </button>
            </span>
          </div>
        </ListGroupItem>
      )}
    </ListGroup>
  );
};

ExtraUriList.propTypes = {
  uriList: PropTypes.array.isRequired,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default ExtraUriList;