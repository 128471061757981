import React from 'react';
import { Alert, Col, NavbarBrand, Row, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { FiLogIn } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import FeedbackModal from '../../dashboard-ui/containers/feedbackModal';
import { __env } from '../../envloader';

const LoginToAccess = ({ t }) => {

  return (
    <>
      <Row className="not-banner align-items-center">
        <Col>
          <Row className="align-items-center">
            <Col className="mx-md-4 brand-div">
              <NavbarBrand tag={Link} to="/">{t('name')}</NavbarBrand>
            </Col>
            <Col className={"text-center"}>
              <FeedbackModal/>
            </Col>
            <Col className="mr-md-4"/>
          </Row>
        </Col>
      </Row>
      <Alert color="warning" className="mt-3 mx-md-2">
        <FiLogIn className="mr-3" />
        {t('common:login_to_access')}
      </Alert>
      { __env.DEMO_DASHBOARD && <div className="d-flex justify-content-center align-items-center">
        <div  style={
          {
            padding: "3% 10%",
            border: "5px solid #3071b3",
            marginTop: 50,
            backgroundColor: "white"
          }}>
          <div className="d-flex justify-content-center align-items-center" style ={{ "color": "black", "fontSize": 28, "fontWeight": 800 }}>
            USER: demo<br/>
            PASS: demo<br/>

          </div>
          <div className="d-flex justify-content-center align-items-center">
            <Button className="mt-3" color="primary" style ={{ "fontSize": 28, "fontWeight": 900, "padding": "20px 80px" }}
              onClick={() => document.getElementById("login").click()}> LOG IN </Button>
          </div>

        </div>
      </div> }
    </>
  );
};

export default withTranslation()(LoginToAccess);

LoginToAccess.propTypes = {
  t: PropTypes.func, //HOC
};
