import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { useTranslation } from 'react-i18next';
import { Button, Label, CardHeader,
  Card, CardBody } from 'reactstrap';
import { useLocation } from 'react-router-dom';

import { withSendRequest } from '../../main/hoc/withSendRequest';
import * as notify from '../../main/utils/notify';
import { getExperimentsRequestParams } from '../utils';
import { ErrorBoundary } from '../../main/hoc/errorboundary';
import { __env } from '../../envloader';

const FiltersResult = ({ allExperimentsCount, selectedExperiments, formControls, sendRequest, toggleCompareShotsModal }) => {

  const { t } = useTranslation();

  const location = useLocation();

  const [ filterVariables, setFilterVariables ] = useState(true);

  const onDownloadExperiments = async (timeDependent) => {
    const variablesIds = formControls.map(item => item.variableId);
    const { conditions, machines, variables } = getExperimentsRequestParams(location.search);

    try {
      const { data: experiments } = await sendRequest('post', '/filterdata-experiments', {
        conditions,
        machines,
        variables,
      });

      const experimentsIds = experiments.map((e) => e.experiment_id);

      const response = await sendRequest('post', '/csv', {
        time_dependent: timeDependent,
        experiment_ids: experimentsIds,
        variable_ids: filterVariables ? variablesIds : null
      });
      if (response?.data?.uuid) {
        const link = document.createElement('a');
        link.href = __env.CATALOG_QT_API_URL+"csv/"+response?.data?.uuid;
        link.download = 'csv';
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      }
      else {
        notify.error(t('filters_result.error_title'), t('filters_result.error_description'));
      }
    }
    catch (err) {
      if (err?.response?.data?.message === "There's no time independent variables in sent request. ") {
        notify.error(
          t('filters_result.no_independent_variables_title'),
          t('filters_result.no_independent_variables_description')
        );
      }
      else {
        notify.error(t('filters_result.error_title'), t('filters_result.error_description'));
      }
    }
  };

  return (
    <><Card className='shadow-sm my-3'>
      <CardHeader>{t('filters_result.results_header')}</CardHeader>
      <CardBody>
        <div>
          <span>{allExperimentsCount}{' '}{t('filters_result.results_number_label')}</span>
        </div>

        <div className='d-flex flex-column align-items-start mt-1' >
          <div className={"custom-switch custom-control mb-1"}>
            <input
              id={'all-vs'}
              type='checkbox'
              checked={ filterVariables }
              className={"custom-control-input"}
              onChange={() => setFilterVariables(!filterVariables)} />
            <Label for={'all-vs'} className={"custom-control-label"}>
              {t('filters_result.download_data_for_all_variables')}
            </Label>
          </div>
          <Button
            type='button'
            color='link'
            className='p-0'
            size='sm'
            onClick={() => { !__env.DEMO_DASHBOARD && onDownloadExperiments(true);} }
            disabled = { __env.DEMO_DASHBOARD }
          >
            {t('filters_result.download_time_dependent')}
          </Button>
          <Button
            type='button'
            color='link'
            className='p-0'
            size='sm'
            onClick={() => { !__env.DEMO_DASHBOARD && onDownloadExperiments(false);} }
            disabled = { __env.DEMO_DASHBOARD }
          >
            {t('filters_result.download_time_independent')}
          </Button>
        </div>
      </CardBody>
    </Card>

    <Card className='shadow-sm my-3'>
      <CardHeader>{t('filters_result.selected_results_header')}</CardHeader>
      <CardBody>
        <p>{selectedExperiments.size}{' '}{t('filters_result.selected_results_number_label')}</p>
        <Button onClick={toggleCompareShotsModal} disabled={selectedExperiments.size < 2} title={t("open_compare_shots_modal_button_tooltip")}>
          {t("open_compare_shots_modal_button")}
        </Button>
      </CardBody>
    </Card>
    </>

  );
};

FiltersResult.propTypes = {
  allExperimentsCount: PropTypes.number,
  selectedExperiments: PropTypes.object,
  formControls: PropTypes.array.isRequired,
  toggleCompareShotsModal:PropTypes.func,
  sendRequest: PropTypes.func.isRequired
};

export default compose(
  withSendRequest,
  ErrorBoundary((props) => props.t('filters_result'))
)(FiltersResult);
