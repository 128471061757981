import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { compose } from 'redux';
import { Button, Form, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import PropTypes from 'prop-types';

import { withSendRequest } from '../../main/hoc/withSendRequest';
import { ErrorBoundary } from '../../main/hoc/errorboundary';
import * as notify from '../../main/utils/notify';
import Loading from '../../main/components/loading';

const AddExperimentModal = ({ isOpen, toggle, sendRequest }) => {
  const { t } = useTranslation();

  const [ uri, setUri ] = useState('');
  const [ isLoading, setIsLoading ] = useState(false);

  const submitRequest = async (e) => {
    e.preventDefault();

    setIsLoading(true);
    try {
      await sendRequest('post', '/request', { uri: uri });
    }
    catch (error) {
      const errorBody = error?.response?.status === 400 && error?.response?.data?.message
        ? error.response.data.message
        : t('add_experiment_modal.notifications.create_error.body');
      notify.error(
        t('add_experiment_modal.notifications.create_error.title'),
        errorBody
      );
      setIsLoading(false);
      return;
    }
    setIsLoading(false);

    notify.success(
      t('add_experiment_modal.notifications.create_success.title'),
      t('add_experiment_modal.notifications.create_success.body')
    );
    toggle();
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} size='lg'>
      <Form onSubmit={submitRequest}>
        <ModalHeader toggle={toggle}>{t('add_experiment_modal.header_title')}</ModalHeader>
        <ModalBody>
          <Label htmlFor='uri'>{t("add_experiment_modal.uri_input_label")}</Label>
          <Input
            type='text'
            name='uri'
            value={uri}
            placeholder={t("add_experiment_modal.uri_input_placeholder")}
            onChange={(e) => setUri(e.target.value)}
            required
          />
        </ModalBody>
        <ModalFooter>
          {
            isLoading ?
              <Loading /> :
              <Button type='submit' color='primary'>
                {t("add_experiment_modal.submit_button_label")}
              </Button>
          }
          <Button type='button' onClick={toggle}>
            {t("add_experiment_modal.cancel_button_label")}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

AddExperimentModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  sendRequest: PropTypes.func, // HOC
};

export default compose(
  withSendRequest,
  ErrorBoundary((props) => props.t('components.add_experiment_modal'))
)(AddExperimentModal);
